import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import useStore from "../../store";
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { menuItems, settings } from "../../constants/constants";

const CommonAppBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profileDetail = useStore((state) => state.profileDetail);
  const userData = useStore((state) => state.userData);
  const setUserData = useStore((state) => state.setUserData);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleLogin = () => {
    if (userData?.isUserAuthenticated) {
      setUserData(undefined);
      navigate("/login");
    } else {
      navigate("/login");
    }
  };

  const handleProfile = () => {
    navigate("/profile");
  };

  const handleDownload = () => {
    navigate("/go/main/download");
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onUserMenuClick = (path) => {
    if (path === "logout") {
      setUserData(undefined);
      navigate("/login");
    } else navigate(`/${path}`);
  };

  const handleMenuItemClick = (path) => {
    if (path === "/business") {
      window.location.href = "https://business.slenky.com/slenky";
    } else {
      navigate(path);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img
        src="/assets/images/slenky-logo.svg"
        alt="SLENKY"
        style={{ width: "100px", margin: "16px auto" }}
        onClick={handleLogoClick}
      />
      <List>
        {menuItems?.map((item) => (
          <ListItem
            button
            key={item?.path}
            onClick={() => handleMenuItemClick(item.path)}
          >
            <ListItemText primary={item?.name} />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={handleDownload}>
          <ListItemText primary="Download App" />
        </ListItem>
        <ListItem button onClick={handleProfile}>
          <ListItemText primary={"Profile"} />
        </ListItem>
        <ListItem button onClick={handleLogin}>
          <ListItemText
            primary={userData?.isUserAuthenticated ? "Logout" : "Login"}
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky" className="menu-bar">
      <Toolbar>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img
            src="/assets/images/slenky-logo.svg"
            alt="SLENKY"
            style={{ width: "100px", cursor: "pointer" }}
            onClick={handleLogoClick}
          />
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            gap: 2,
            color: "white",
            flexGrow: 1,
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          className="fw-600 w-100"
        >
          <img
            src="/assets/images/slenky-logo.svg"
            alt="SLENKY"
            style={{ width: "100px", cursor: "pointer" }}
            onClick={handleLogoClick}
          />
          {menuItems?.map((item) => (
            <Button
              key={item?.path}
              color="inherit"
              sx={{fontSize:'1.25rem',fontWeight:'500'}}
              onClick={() => handleMenuItemClick(item.path)}
              className={
                location?.pathname?.split("/")[1] ===
                item?.path?.replace("/", "")
                  ? "menu-active"
                  : ""
              }
            >
              {item?.name}
            </Button>
          ))}
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            ml: "auto",
          }}
        >
          <Button
            title="Download App"
            onClick={handleDownload}
            sx={{color: "white", minWidth: { md: "140px" },fontSize:'1rem',fontWeight:'500' }}
          >
            Download App
          </Button>
          {userData?.isUserAuthenticated ? (
            <Tooltip title="Open settings">
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
                sx={{
                  borderRadius: "50%",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#80808087",
                  },
                }}
              >
                <Avatar
                  alt="Profile Picture"
                  src={
                    userData?.user.avatar
                      ? userData?.user.avatar
                      : "/assets/images/defaultProfile.png"
                  }
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Button style={{ color: "white",fontSize:'1rem',fontWeight:'500' }} onClick={handleLogin}>
              Login
            </Button>
          )}
        </Box>
      </Toolbar>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings?.map((setting) => (
          <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
            <Button
              textAlign="center"
              onClick={() => onUserMenuClick(setting?.path)}
            >
              {setting?.name}
            </Button>
          </MenuItem>
        ))}
      </Menu>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default CommonAppBar;
