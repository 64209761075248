import { lazy } from "react";

const routes = [
  {
    path: "/",
    exact: true,
    element: lazy(() => import("../pages/business/index")),
  },
  {
    path: "/shots",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/shots/index")),
  },
  {
    path: "/shots/:slug",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/shots/shotDetails")),
  },
  {
    path: "/brands",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/brands/index")),
  },
  {
    path: "/brands/:id",
    exact: true,
    auth: true,
    element: lazy(() => import("../components/brandDetails")),
  },
  {
    path: "/cities/:id",
    exact: true,
    auth: true,
    element: lazy(() => import("../components/brandDetails")),
  },
  // {
  //   path: "/educational/:id",
  //   exact: true,
  //   auth: true,
  //   element: lazy(() => import("../components/brandDetails")),
  // },
  {
    path: "/business",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/business/index")),
  },
  {
    path: "/cities",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/cities/index")),
  },
  // {
  //   path: "/educational",
  //   exact: true,
  //   auth: true,
  //   element: lazy(() => import("../pages/educational/index")),
  // },
  {
    path: "/profile",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/profile")),
  },
  {
    path: "go/main/download",
    element: lazy(() => import("../pages/download/index")),
  },
  {
    path: "/profile/edit",
    exact: true,
    auth: true,
    element: lazy(() => import("../pages/profile/EditProfile")),
  },
  {
    path: "/go/blog/help",
    exact: true,
    element: lazy(() => import("../pages/help/index")),
  },
  {
    path: "/go/blog/about-slenky",
    exact: true,
    element: lazy(() => import("../pages/about/index")),
  },
  {
    path: "/go/main/how-it-works",
    exact: true,
    element: lazy(() => import("../pages/how/index")),
  },
  {
    path: "/go/blog/terms",
    exact: true,
    element: lazy(() => import("../pages/terms/index")),
  },
  {
    path: "/go/*",
    element: lazy(() => import("../pages/business/index")),
  },
  {
    path: "/myblog/*",
    element: lazy(() => import("../pages/business/index")),
  },
  
];

export default routes;
